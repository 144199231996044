import { createContext, useState, useEffect } from "react";

const getNewCartStateAdd = (cartItems, itemToAdd) => {
    return [...cartItems, { ...itemToAdd }];
};

const getNewCartStateRemove = (cartItems, indexToRemove) => {
    cartItems.splice(indexToRemove, 1);
    const newCartItemsState = [...cartItems];
    return newCartItemsState;
};

const getNewCartStateClear = (cartItems, itemToClear) => {
    return cartItems.filter((cartItem) => cartItem.id !== itemToClear.id);
};

export const CartContext = createContext({
    isCartOpen: false,
    setIsCartOpen: () => {},
    cartItems: [],
    addItemToCart: () => {},
    cartItemCount: 0,
    removeItemFromCart: () => {},
    clearItemFromCart: () => {},
    clearAllItemsFromCart: () => {},
    subTotal: 0,
    total: 0,
});

const getInititialState = () => {
    const cartItems = localStorage.getItem("cartItems");
    return cartItems ? JSON.parse(cartItems) : [];
};

export const CartProvider = (props) => {
    const [isCartOpen, setIsCartOpen] = useState(false);
    const [cartItems, setCartItems] = useState(getInititialState);
    const [cartItemCount, setCartItemCount] = useState(0);
    const [subTotal, setCartSubTotal] = useState(0);
    const [total, setCartTotal] = useState(0);

    const addItemToCart = (productToAdd) => {
        setCartItems(getNewCartStateAdd(cartItems, productToAdd));
    };

    const removeItemFromCart = (indexToRemove) => {
        setCartItems(getNewCartStateRemove(cartItems, indexToRemove));
    };

    const clearItemFromCart = (itemToRemove) => {
        setCartItems(getNewCartStateClear(cartItems, itemToRemove));
    };

    const clearAllItemsFromCart = () => {
        setCartItems([]);
    };

    useEffect(() => {
        const count = cartItems.reduce(
            (total, cartItem) => total + cartItem.quantity,
            0
        );
        setCartItemCount(count);
        localStorage.setItem("cartItems", JSON.stringify(cartItems));
    }, [cartItems]);

    useEffect(() => {
        let total = cartItems.reduce(
            (total, cartItem) => total + cartItem.price * cartItem.quantity,
            0
        );

        let modifierTotals = 0;

        cartItems.map((item) => {
            item.modifiers.map((modifier) => {
                modifierTotals += modifier.price * item.quantity;
            });
        });

        total += modifierTotals;
        setCartSubTotal(total);
        setCartTotal((total + total * 0.1).toFixed(2));
    }, [cartItems]);

    const value = {
        isCartOpen,
        setIsCartOpen,
        addItemToCart,
        cartItems,
        cartItemCount,
        removeItemFromCart,
        clearItemFromCart,
        clearAllItemsFromCart,
        subTotal,
        total,
    };

    return (
        <CartContext.Provider value={value}>
            {props.children}
        </CartContext.Provider>
    );
};
