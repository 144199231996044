import "./FooterOverlayStyles.css";

const FooterOverlay = () => {
    return (
        <div className="app--footerOverlay">
            <div className="app--footerOverlay-black" />
            <div className="app--footerOverlay-img app--background" />
        </div>
    );
};

export default FooterOverlay;
