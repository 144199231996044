import { images } from "../../constants";
import "./AboutUsStyles.css";

const AboutUs = () => {
    return (
        <div
            className="app--aboutus app--background flex--center section--padding"
            id="about"
        >
            <div className="app--aboutus-overlay flex--center">
                <img src={images.Y} alt="g watermark" />
            </div>
            <div className="app--aboutus-content flex--center">
                <div className="app--aboutus-content-image">
                    <img src={images.bentoGoldBorder} alt="about_image" />
                </div>

                <div className="app--aboutus-content-knife flex--center">
                    <img src={images.knife} alt="about_knife" />
                </div>

                <div className="app--aboutus-content-about">
                    <h1 className="headtext--cormorant">About Us</h1>
                    <img
                        src={images.spoon}
                        alt="about spoon"
                        className="spoon--img"
                    />
                    <p className="p--opensans">
                        Since September of 2010, we've been serving up
                        delicious, high-quality dishes inspired by the cuisines
                        of Asia and beyond. Located at the heart of
                        Jacksonville, we take pride in providing a dining
                        experience that's both delicious and adventurous.
                        Customer satisfaction is incredibly important to us,
                        which is why we're committed to using only the freshest
                        ingredients and preparing each dish to perfection. At
                        our restaurant, we're passionate about providing a warm
                        and welcoming atmosphere for our guests, and we believe
                        that our commitment to using the best ingredients is
                        evident in every bite. Come and join us for a culinary
                        journey you won't forget!
                    </p>
                </div>
            </div>
        </div>
    );
};
export default AboutUs;
