import "./SlideShowStyles.css";
const SlideShow = (props) => {
    const { images } = props;
    return (
        <div className="app--specialMenu-menu-img">
            <img src={images.jsuRoll} alt="menu separator" />
        </div>
    );
};
export default SlideShow;
