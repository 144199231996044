import AboutUs from "../../container/AboutUs/AboutUsComponent";
import Footer from "../../container/Footer/FooterComponent";
import Gallery from "../../container/Gallery/GalleryComponent";
import Header from "../../container/Header/HeaderComponent";
import SpecialMenu from "../../container/SpecialMenu/SpecialMenuComponent";

const HomePage = () => {
    return (
        <div className="app--homepage">
            <Header />
            <AboutUs />
            <SpecialMenu />
            <Gallery />
            <Footer />
        </div>
    );
};

export default HomePage;
