import Footer from "../../container/Footer/FooterComponent";

import Menu from "../../container/Menu/MenuComponent";

const MenuPage = () => {
    return (
        <div>
            <Menu />
            <Footer />
        </div>
    );
};

export default MenuPage;
