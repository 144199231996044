import SubHeading from "../SubHeading/SubHeadingComponent";
import "./NewsLetterStyles.css";
import { useState } from "react";
import $ from "jquery";

const NewsLetter = () => {
    const [feedback, setFeedback] = useState("");
    const [name, setName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState();
    const [email, setEmail] = useState("");
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const handleSubmit = () => {
        setHasSubmitted(true);

        let emailBody =
            "Name: " +
            name +
            "\n" +
            "Phone Number: " +
            phoneNumber +
            "\n" +
            "Email: " +
            email +
            "\n" +
            "Feedback: " +
            "\n" +
            feedback;

        let emailData = {
            from_email: "johnnylin0588@gmail.com",
            to_email: "johnlin2922@gmail.com",
            subject: "Feedback from Website",
            msg: emailBody,
            name: "Feedback",
        };
        sendEmail(emailData);
    };

    const handleFeedbackInput = (event) => {
        setFeedback(event.target.value);
    };

    const handleEmailInput = (event) => {
        setEmail(event.target.value);
    };

    const handleNameInput = (event) => {
        setName(event.target.value);
    };

    const handlePhoneNumberInput = (event) => {
        setPhoneNumber(event.target.value);
    };

    const sendEmail = (data) => {
        $.ajax({
            url: "https://johngeestudios.com/api/email.php",
            type: "POST",
            data: data,
            cache: false,
            success: function (data) {
                // Success..
                //console.log("success", data);
            }.bind(this),
            // Fail..
            error: function (xhr, status, err) {
                //console.log(xhr, status);
                //console.log(err);
            }.bind(this),
        });
    };

    const onFeedbackChange = (event) => {
        setFeedback(event.target.value);
    };

    const onNameChange = (event) => {
        setName(event.target.value);
    };

    const onEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const onPhoneNumberChange = (event) => {
        setPhoneNumber(event.target.value);
    };

    //ToDo: Make a Submit Function

    return (
        <div className="app--newsletter">
            <div className="app--newsletter-heading">
                <SubHeading title="Feedback" />
                <h1 className="headtext--cormorant">
                    Your Opinion Matters to Us
                </h1>
                {!hasSubmitted && (
                    <p className="p--opensans">Tell Us How We Can Improve</p>
                )}
                {hasSubmitted && (
                    <p className="p--opensans">
                        Thankyou. Your feedback has been submitted
                    </p>
                )}
            </div>

            {!hasSubmitted && (
                <div className="app--newsletter-input flex--center">
                    <div className="feedback-input-row">
                        <input
                            className="feedback-input feedback-name"
                            type="text"
                            placeholder="Name (optional)"
                            value={name}
                            onChange={handleNameInput}
                        />
                        <input
                            className="feedback-input feedback-name"
                            type="phone"
                            placeholder="Phone Number (optional)"
                            value={phoneNumber}
                            onChange={handlePhoneNumberInput}
                        />
                    </div>
                    <input
                        className="feedback-email feedback-input"
                        type="email"
                        placeholder="Email (optional)"
                        value={email}
                        onChange={handleEmailInput}
                    />
                    <textarea
                        className="feedback-body feedback-input feedback-entry"
                        type="phone"
                        placeholder="Please Enter your feedback"
                        onChange={handleFeedbackInput}
                        value={feedback}
                    />
                    <button className="custom--button" onClick={handleSubmit}>
                        Submit
                    </button>
                </div>
            )}
        </div>
    );
};
export default NewsLetter;
