import "./ConfirmationStyles.css";
import ConfirmationItem from "./ConfirmationItem";
import ConfirmationTotal from "./ConfirmationTotalComponents";
import { getOrderConfirmation } from "../../../utilities/firebase/firebaseUtilities";
import { useState } from "react";
import { useEffect } from "react";
import { CircleLoader } from "react-spinners";
import buildEmail from "../Checkout/emailBuilder";
import { CartContext } from "../../../contexts/CartContext";
import { useContext } from "react";

const Confirmation = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isValidOrder, setIsValidOrder] = useState(false);
    const [customerOrder, setCustomerOrder] = useState({});
    const searchParams = new URLSearchParams(document.location.search);
    const orderNumber = searchParams.get("orderNumber");

    const { clearAllItemsFromCart } = useContext(CartContext);

    const handleClearCart = () => {
        //console.log("order successfully submitted, clearing all cart items");
        clearAllItemsFromCart();
    };

    useEffect(() => {
        try {
            console.log(
                "orderNumber",
                getOrderConfirmation(orderNumber, setIsValidOrder)
            );

            getOrderConfirmation(orderNumber, setIsValidOrder).then((order) => {
                //console.log("then call", order);
                setCustomerOrder(order);
                handleClearCart();
            });
            // switch loading to false after fetch is complete
            // setTimeout(() => {
            //     //console.log(
            //         "pausing app for 4 seconds, this will print after it returns"
            //     );
            //     setIsLoading(false);
            // }, 4000);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            //console.log(error);
        }
    }, []);

    return isLoading ? (
        <CircleLoader color="#dcca87" />
    ) : (
        <div className="app--confirmationPage app--background">
            <div className="app--confirmation-mainContainer app--scrollable">
                {isValidOrder && (
                    <div className="app--cartViewContainer app--onlineOrder-cartContainer">
                        <div className="confirmation-title-container">
                            <p className="p--cormorant confirmation-title">
                                Your Order
                            </p>
                        </div>

                        <table className="confirmationTable-container">
                            <thead>
                                <tr>
                                    <th>Items</th>
                                    <th>Quantity</th>
                                    <th>Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {customerOrder.items.map((item, index) => {
                                    return (
                                        <ConfirmationItem
                                            key={
                                                "cartItemKey: " +
                                                item.name +
                                                index
                                            }
                                            item={item}
                                            itemIndex={index}
                                        />
                                    );
                                })}
                            </tbody>

                            <ConfirmationTotal
                                subTotal={customerOrder.subTotal}
                            />
                        </table>
                        <div className="customer-info">
                            <div>Order Number: {orderNumber}</div>

                            <div>{customerOrder.customerInfo.name}</div>

                            <div>{customerOrder.customerInfo.phoneNumber}</div>

                            <div>{customerOrder.customerInfo.email}</div>

                            <div>{customerOrder.dateTime}</div>

                            <div>{customerOrder.pickupTime} - pick up time</div>
                            <div>
                                Special Instructions:{" "}
                                {customerOrder.specialInstructions}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Confirmation;
