import "./RecentOrderStyles.css";
import { getRecentOrders } from "../../../utilities/firebase/firebaseUtilities";
import { useState } from "react";
import { useEffect } from "react";
import { CircleLoader } from "react-spinners";
import ConfirmationItem from "../Confirmation/ConfirmationItem";
import ConfirmationTotal from "../Confirmation/ConfirmationTotalComponents";

const RecentOrders = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isValidOrder, setIsValidOrder] = useState(false);
    const searchParams = new URLSearchParams(document.location.search);
    const orderNumber = searchParams.get("orderNumber");
    const [customerOrders, setCustomerOrders] = useState({});

    useEffect(() => {
        try {
            getRecentOrders(5).then((orders) => {
                setCustomerOrders(orders);
                setIsValidOrder(true);
            });

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            //console.log(error);
        }
    }, []);

    function epochToDateTime(epochTime) {
        console.log("input" + epochTime);
        const date = new Date(Number(epochTime));

        // Convert to Central Time (CT) using options for toLocaleString
        const options = {
            timeZone: "America/Chicago",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
        };
        const centralTimeString = date.toLocaleString("en-US", options);
        console.log("output" + centralTimeString);
        return centralTimeString;
    }

    return isLoading ? (
        <CircleLoader color="#dcca87" />
    ) : (
        <div className="app--confirmationPage app--background">
            {Object.keys(customerOrders).map((key, index) => {
                const customerOrder = customerOrders[key];
                return (
                    <div
                        key={"recentOrder" + index}
                        className="app--recentOrders-mainContainer"
                    >
                        {isValidOrder && (
                            <div className="app--cartViewContainer app--onlineOrder-cartContainer recent-order-container">
                                <div className="confirmation-title-container">
                                    <p className="p--cormorant confirmation-title">
                                        Order, Placed on:{" "}
                                        {epochToDateTime(
                                            customerOrder.orderNumber
                                        )}
                                    </p>
                                </div>

                                <table className="confirmationTable-container">
                                    <thead>
                                        <tr>
                                            <th>Items</th>
                                            <th>Quantity</th>
                                            <th>Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {customerOrder.items.map(
                                            (item, index) => {
                                                return (
                                                    <ConfirmationItem
                                                        key={
                                                            "cartItemKey: " +
                                                            item.name +
                                                            index
                                                        }
                                                        item={item}
                                                        itemIndex={index}
                                                    />
                                                );
                                            }
                                        )}
                                    </tbody>

                                    <ConfirmationTotal
                                        subTotal={customerOrder.subTotal}
                                    />
                                </table>
                                <div className="customer-info">
                                    <div>Order Number: {orderNumber}</div>

                                    <div>{customerOrder.customerInfo.name}</div>

                                    <div>
                                        {customerOrder.customerInfo.phoneNumber}
                                    </div>

                                    <div>
                                        {customerOrder.customerInfo.email}
                                    </div>

                                    <div>{customerOrder.dateTime}</div>

                                    <div>
                                        {customerOrder.pickupTime} - pick up
                                        time
                                    </div>
                                    <div>
                                        Special Instructions:{" "}
                                        {customerOrder.specialInstructions}
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="app--onlineOrder-divider"></div>
                    </div>
                );
            })}
            {/* <div className="app--confirmation-mainContainer app--scrollable">
                        {isValidOrder && (
                            <div className="app--cartViewContainer app--onlineOrder-cartContainer">
                                <div className="confirmation-title-container">
                                    <p className="p--cormorant confirmation-title">
                                        Your Order
                                    </p>
                                </div>

                                <table className="confirmationTable-container">
                                    <thead>
                                        <tr>
                                            <th>Items</th>
                                            <th>Quantity</th>
                                            <th>Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {customerOrder.items.map(
                                            (item, index) => {
                                                return (
                                                    <ConfirmationItem
                                                        key={
                                                            "cartItemKey: " +
                                                            item.name +
                                                            index
                                                        }
                                                        item={item}
                                                        itemIndex={index}
                                                    />
                                                );
                                            }
                                        )}
                                    </tbody>

                                    <ConfirmationTotal
                                        subTotal={customerOrder.subTotal}
                                    />
                                </table>
                                <div className="customer-info">
                                    <div>Order Number: {orderNumber}</div>

                                    <div>{customerOrder.customerInfo.name}</div>

                                    <div>
                                        {customerOrder.customerInfo.phoneNumber}
                                    </div>

                                    <div>
                                        {customerOrder.customerInfo.email}
                                    </div>

                                    <div>{customerOrder.dateTime}</div>

                                    <div>
                                        {customerOrder.pickupTime} - pick up
                                        time
                                    </div>
                                    <div>
                                        Special Instructions:{" "}
                                        {customerOrder.specialInstructions}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div> */}
        </div>
    );
};

export default RecentOrders;
