import pageOne from "../../assets/menu/YamatoMenu-page-001.jpg";
import pageTwo from "../../assets/menu/YamatoMenu-page-002.jpg";
import pageThree from "../../assets/menu/YamatoMenu-page-003.jpg";
import pageFour from "../../assets/menu/YamatoMenu-page-004.jpg";
import pageFive from "../../assets/menu/YamatoMenu-page-005.jpg";
import pageSix from "../../assets/menu/YamatoMenu-page-006.jpg";
import pageSeven from "../../assets/menu/YamatoMenu-page-007.jpg";
import "./MenuStyles.css";

const Menu = (props) => {
    return (
        <div className="menu--page-container">
            <img className="menu--one-page" src={pageOne}></img>
            <img className="menu--two-page" src={pageTwo}></img>
            <img className="menu--two-page" src={pageThree}></img>
            <img className="menu--two-page" src={pageFour}></img>
            <img className="menu--two-page" src={pageFive}></img>
            <img className="menu--two-page" src={pageSix}></img>
            <img className="menu--one-page" src={pageSeven}></img>
        </div>
    );
};
export default Menu;
