import "./CartStyles.css";
import CartItem from "../CartItem/CartItemComponent";
import Totals from "../Totals/TotalsContainer";
import React, { useContext, useState } from "react";
import { CartContext } from "../../../contexts/CartContext";

// interface CartItem {
//     name: string;
//     price: number;
//     modifiers: string[];
//     modifiers: {[value: "value", price: ""]}
//     imageUrl: "http://jacksonvilleyamato.com/wp-content/uploads/rpress/2021/01/Screen-Shot-2021-01-26-at-3.11.44-AM.png";
//     quantity: number;
// }

const Cart = (props) => {
    const { cartItems } = useContext(CartContext);

    const { showCheckout, closeCheckout, setShowCheckout } = props;

    const handleToggleCheckout = () => {
        if (cartItems.length === 0) {
            alert("Add some items to proceed with checkout");
            return;
        }
        setShowCheckout(!showCheckout);
    };

    return (
        <div className="app--cartViewContainer app--scrollable app--onlineOrder-cartContainer">
            <div className="cart--title">
                <p className="p--cormorant cart-title-text">Cart</p>
            </div>

            {cartItems.map((item, index) => {
                return (
                    <CartItem
                        key={"cartItemKey: " + item.name + index}
                        item={item}
                        itemIndex={index}
                    />
                );
            })}

            <Totals />

            <button
                className="custom--button checkout-button"
                onClick={handleToggleCheckout}
            >
                Checkout
            </button>
        </div>
    );
};

export default Cart;
