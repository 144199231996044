import "./FoodItemQuantitySelectorStyles.css";
import { FiMinusSquare, FiPlusSquare } from "react-icons/fi";

const FoodItemQuantitySelector = (props) => {
    const { incrementQuantity, decrementQuantity, quantity } = props;

    return (
        <div className="app--foodItem-quantitySelectorContainer noselect">
            <div className="foodItem-mathIcons">
                <FiMinusSquare
                    className="app--foodItem-quantitySelectorButton"
                    onClick={decrementQuantity}
                />
            </div>
            <div className="foodItem-quantityContainer">
                <a className="app--foodItem-quantitySelectorButton foodItem-quantityValue">
                    {quantity}
                </a>
            </div>
            <div className="foodItem-mathIcons">
                <FiPlusSquare
                    className="app--foodItem-quantitySelectorButton"
                    onClick={incrementQuantity}
                />
            </div>
        </div>
    );
};

export default FoodItemQuantitySelector;
