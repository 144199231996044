import "./App.css";
import NavBar from "./components/Navbar/NavBarComponent";
import OnlineOrderPage from "./pages/OnlineOrder/OnlineOrderPage";
import { Routes, Route } from "react-router-dom";
import React from "react";
import HomePage from "./pages/HomePage/HomePage";
import Confirmation from "./container/OnlineOrder/Confirmation/ConfirmationComponent";
import MenuPage from "./pages/Menu/MenuPage";
import RecentOrders from "./container/OnlineOrder/Recent/RecentOrders";

function App() {
    return (
        <Routes>
            <Route path="/" element={<NavBar />}>
                <Route index={true} element={<HomePage />}></Route>
                <Route
                    path="online-order"
                    element={<OnlineOrderPage />}
                ></Route>
                <Route path="menu" element={<MenuPage />}></Route>
                <Route path="confirmation" element={<Confirmation />}></Route>
                <Route path="recent" element={<RecentOrders />}></Route>
            </Route>
        </Routes>
    );
}

export default App;
