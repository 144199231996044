import CategoryHeading from "../../../components/CategoryHeading/CategoryHeading";
import FoodItem from "../FoodItem/FoodItem";
import "./CategoryStyles.css";

const Category = (props) => {
    const { categoryName, categoryRefs, items, refId } = props;

    return (
        <div
            className="category--container"
            id={categoryName}
            ref={(element) => (categoryRefs.current[refId] = element)}
        >
            <div>
                {items.length > 0 ? (
                    <CategoryHeading title={categoryName} />
                ) : (
                    <></>
                )}
                {items.length > 0 ? (
                    Object.keys(items).map((itemName, index) => {
                        ////console.log(items[itemName].name);
                        return (
                            <FoodItem
                                itemType={categoryName}
                                key={"foodItemId: " + items[itemName] + index}
                                item={items[itemName]}
                            />
                        );
                    })
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};

export default Category;
