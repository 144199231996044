import "./ModificationStyles.css";
import { useState } from "react";
import { useEffect } from "react";

const Modification = (props) => {
    const { name, price } = props.entry;

    //maybe make name an array? and simply load each element of the name array as
    //mutually exclusive selections.
    //The vast majority of modifications will be check boxes, but a few will have
    //name array with size greater than 1. and Make those mutually exclusive.
    //poke bowl protiens? double salad or double soup

    const [isChecked, setIsChecked] = useState(false);

    const isMultiItem = typeof props.entry.name !== "string"; //string is single item, object or array is multi-item

    let boolArr = [];
    if (isMultiItem) {
        props.entry.name.map(() => {
            boolArr.push(false);
        });
    }
    const [isMutuallyChecked, setIsMutuallyChecked] = useState(boolArr);

    const handleOnChange = (event, isExclusive, index) => {
        if (isExclusive) {
            let arr = [];
            isMutuallyChecked.map((bool, index) => {
                arr.push(false);
                props.addModificationCallback(false, {
                    name: name[index],
                    price,
                });
            });

            arr[index] = !isMutuallyChecked[index];

            props.addModificationCallback(!isMutuallyChecked[index], {
                name: name[index],
                price,
            });

            setIsMutuallyChecked(arr);
        } else {
            props.addModificationCallback(!isChecked, { name, price });
            setIsChecked(!isChecked);
        }
    };

    return isMultiItem ? (
        props.entry.name.map((name, index) => {
            return (
                <div key={name + ", " + index} className="modification-entry">
                    <div className="modification-name">
                        <a className="modifier-spacer">&nbsp; &nbsp;</a>
                        <input
                            type="checkbox"
                            id={name}
                            name={name}
                            value={name}
                            onChange={(event) =>
                                handleOnChange(event, true, index)
                            }
                            checked={isMutuallyChecked[index]}
                        />

                        <label htmlFor={name}>{name}</label>
                    </div>
                    {price !== 0 ? (
                        <div className="modification-price">
                            <label>${price.toFixed(2)}</label>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            );
        })
    ) : (
        <div className="modification-entry">
            <div className="modification-name">
                <a className="modifier-spacer">&nbsp; &nbsp;</a>
                <input
                    type="checkbox"
                    id={name}
                    name={name}
                    value={name}
                    onChange={handleOnChange}
                    checked={isChecked}
                />

                <label htmlFor={name}>{name}</label>
            </div>
            {price !== 0 ? (
                <div className="modification-price">
                    <label>${price.toFixed(2)}</label>
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default Modification;
