import React, { useState, useEffect } from "react";

function PickupTimeSelector(props) {
    const { setPickupTime } = props;

    const [times, setTimes] = useState([]);
    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        // Get current time
        const offsetInMinutes = 11;
        setCurrentTime(new Date());
        console.log(currentTime);
        // Create an array of times in 15 minute increments
        const timesArray = [];
        let startTime = new Date();
        startTime.setHours(currentTime.getHours());
        startTime.setMinutes(0);
        startTime.setSeconds(0);
        for (let i = 0; i < 96; i++) {
            // 96 times (24*4)
            const time = new Date(startTime.getTime() + i * 15 * 60 * 1000);
            if (time.getHours() >= 11 && time.getHours() < 22) {
                if (time.getHours() === currentTime.getHours()) {
                    if (
                        time.getMinutes() <
                            currentTime.getMinutes() + offsetInMinutes ||
                        time.getMinutes() > 60 - offsetInMinutes
                    ) {
                        continue;
                    }
                }
                if (time.getHours() === currentTime.getHours() + 1) {
                    console.log(time.getMinutes());
                    if (time.getMinutes() === 0) {
                        if (currentTime.getMinutes() + offsetInMinutes > 60) {
                            continue;
                        }
                    }
                }
                let hours = time.getHours();
                let minutes = time.getMinutes();
                let hour = hours > 12 ? hours - 12 : hours;
                hour = hour === 0 ? 12 : hour;
                const ampm = hours >= 12 ? "PM" : "AM";
                timesArray.push(`${hour}:${("0" + minutes).slice(-2)} ${ampm}`);
            } else if (time.getHours() > 22) {
                break;
            }
        }
        setTimes(timesArray);
    }, []);

    useEffect(() => {
        setPickupTime(times[0]);
    }, [times]);

    const handlePickupTimeSelection = (event) => {
        setPickupTime(event.target.value);
    };
    return (
        <select onChange={handlePickupTimeSelection}>
            {times.map((time, index) => (
                <option key={index} value={time}>
                    {time}
                </option>
            ))}
        </select>
    );
}

export default PickupTimeSelector;
