import FooterOverlay from "../../components/FooterOverlay/FooterOverlayComponent";
import "./FooterStyles.css";

import { FiFacebook, FiTwitter, FiInstagram } from "react-icons/fi";
import NewsLetter from "../../components/NewsLetter/NewsLetterComponent";
import { images } from "../../constants";

const Footer = () => {
    return (
        <div className="app--footer section--padding" id="contact">
            <FooterOverlay />
            <NewsLetter />

            <div className="app--footer-links">
                <div className="app--footer-links-contact">
                    <h1 className="app--footer-headtext">Contact Us</h1>
                    <p className="p--opensans">
                        105 Mountain St NW, Jacksonville, AL 36265
                    </p>
                    <p className="p--opensans">256-782-0588</p>
                    <p className="p--opensans">256-782-0688</p>
                    <p className="p--opensans">256-365-6231</p>
                </div>
                <div className="app--footer-links-logo">
                    <img src={images.siteLogo} alt="footer_logo" />
                    <p className="p--opensans">
                        "Cooking is like painting or writing a song. Just as
                        there are only so many notes or colors, there are only
                        so many flavors - it's how you combine them that sets
                        you apart."
                    </p>
                    <img
                        src={images.spoon}
                        alt="spoon"
                        className="spoon--image"
                        style={{ marginTop: 15 }}
                    />
                    <div className="app--footer-links-icons">
                        <a href="https://www.facebook.com/yamatojacksonville">
                            <FiFacebook />
                        </a>
                        <a href="https://www.instagram.com/yamatojacksonvilleal/">
                            <FiInstagram />
                        </a>
                        {/* <FiTwitter /> */}
                    </div>
                </div>
                <div className="app--footer-links-work">
                    <h1 className="app--footer-headtext">Working Hours</h1>
                    <p className="p--opensans">Sunday-Thrusday:</p>
                    <p className="p--opensans">11:00am - 10:00pm</p>
                    <p className="p--opensans">Friday-Saturday:</p>
                    <p className="p--opensans">11:00am - 10:30pm</p>
                </div>
            </div>

            <div className="footer--copyright">
                <p className="p--opensans">
                    Developed by{" "}
                    <a href="https://JohnGeeStudios.com">JohnGeeStudios</a>. All
                    Rights Reserved
                </p>
            </div>
        </div>
    );
};
export default Footer;
