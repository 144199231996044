const getCentralTime = () => {
    // // Create a Date object for September 16, 2023, at 2:30:45 PM (local time)
    //const currentDate = new Date(2023, 8, 16, 14, 30, 45); //Used for testing, modify this value to see if menu items render

    // Get the current date and time in UTC
    const currentDate = new Date();

    // Create a new Date object with the time adjusted for US Central Time (UTC-5 or UTC-6, depending on daylight saving time)
    const centralTimeOffset = currentDate.getTimezoneOffset() / 60 + (currentDate.getMonth() >= 2 && currentDate.getMonth() <= 10 ? 5 : 6);
    const centralTimeDate = new Date(currentDate.getTime() - centralTimeOffset * 60 * 60 * 1000);

    return currentDate;
}

const isTodayAWeekend = () => {
    const centralTimeDate = getCentralTime();

    // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    const dayOfWeek = centralTimeDate.getUTCDay();

    // Check if it's a weekend (Friday, Saturday, or Sunday)
    return dayOfWeek === 6 || dayOfWeek === 0;
}

const isItLunchTime = () => {
    const centralTimeDate = getCentralTime();

    // Get the current hour in US Central Time
    const currentHourInCentralTime = centralTimeDate.getHours();

    // Check if the current time is before 3 PM (15:00) in US Central Time
    return currentHourInCentralTime < 15;
}

export { isTodayAWeekend, isItLunchTime, getCentralTime };