import { useEffect } from "react";
import { useState, createContext } from "react";
import { testItems } from "./MenuItems";
import { modifications } from "./Modifiers";

const getFilteredItems = (menuItems, value) => {
    let filteredResults = [];

    Object.keys(menuItems).map((categoryKey, index) => {
        filteredResults[categoryKey] = {
            categoryName: menuItems[categoryKey].categoryName,
            items: null,
            isLunchCategory: menuItems[categoryKey].isLunchCategory,
        };

        if (menuItems[categoryKey].items !== null) {
            const filteredItems = Object.values(
                menuItems[categoryKey].items
            ).filter((item) => {
                return item.name.toLowerCase().includes(value.toLowerCase());
            });
            filteredResults[categoryKey].items = filteredItems;
        }
    });

    return filteredResults;
};

export const MenuItemsContext = createContext({
    menuItems: {},
    filterMenuItems: () => {},
    modifiers: [],
});

export const MenuItemsProvider = (props) => {
    const [menuItems, setMenuItems] = useState({});
    const [modifiers, setModifiers] = useState([]);

    useEffect(() => {
        setMenuItems(testItems);
        setModifiers(modifications);
    }, []);

    const filterMenuItems = (event) => {
        setMenuItems(getFilteredItems(testItems, event.target.value));
    };

    const value = { menuItems, filterMenuItems, modifiers };

    return (
        <MenuItemsContext.Provider value={value}>
            {props.children}
        </MenuItemsContext.Provider>
    );
};
