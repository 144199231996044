import Footer from "../../container/Footer/FooterComponent";

import OnlineOrder from "../../container/OnlineOrder/OnlineOrderComponent";

const OnlineOrderPage = () => {
    return (
        <div>
            <OnlineOrder />

            {/* <div className="app--onlineOrderPage app--background">
                <div className="app--onlineOrder-mainContainer section--padding">
                    <div className="app--onlineOrder-content error-message">
                        Online Ordering is not available at this time. Sorry for
                        the inconvenience. Please call in to place an order at
                        256 782 0588.
                    </div>
                </div>
            </div> */}

            <Footer />
        </div>
    );
};

export default OnlineOrderPage;
