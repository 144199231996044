import background from "../assets/background.png";
import chef from "../assets/chef.png";
import G from "../assets/G.png";
import gallery01 from "../assets/gallery01.png";
import gallery02 from "../assets/gallery02.png";
import gallery03 from "../assets/gallery03.png";
import gallery04 from "../assets/gallery04.png";
import knife from "../assets/knife.png";
import logo from "../assets/logo.png";
import menu from "../assets/menu.png";
import overlaybg from "../assets/overlaybg.png";
import spoon from "../assets/spoon.svg";
import welcome from "../assets/welcome.png";
import findus from "../assets/findus.png";
import laurels from "../assets/laurels.png";
import award01 from "../assets/award01.png";
import award02 from "../assets/award02.png";
import award03 from "../assets/award03.png";
import award05 from "../assets/award05.png";
import sign from "../assets/sign.png";
import quote from "../assets/quote.png";
import gericht from "../assets/gericht.png";
import introduction from "../assets/images/introduction.png"
import siteLogo from "../assets/images/site-logo.png"
import Y from "../assets/Y.png"
import milkTea from "../assets/slideShow/milkTea.jpg"
import drinks from "../assets/slideShow/drinks.jpg"
import jsuRoll from "../assets/slideShow/jsuRoll.jpg"
import goldBorder from "../assets/images/gold-border-transparant.png"
import bentoGoldBorder from "../assets/images/bento-gold-border.png"
import sushiGoldBorder from "../assets/images/sushi-gold-border.png"

export default {
    sushiGoldBorder,
    bentoGoldBorder,
    background,
    chef,
    G,
    gallery01,
    gallery02,
    gallery03,
    gallery04,
    knife,
    logo,
    menu,
    overlaybg,
    spoon,
    welcome,
    findus,
    laurels,
    award01,
    award02,
    award03,
    award05,
    sign,
    quote,
    gericht,
    introduction,
    siteLogo,
    Y,
    milkTea,
    drinks,
    jsuRoll,
    goldBorder
};
