import FoodItemQuantitySelector from "../FoodItemQuantitySelector/FoodItemQuantitySelectorComponent";
import "./FoodItemStyles.css";
import { CartContext } from "../../../contexts/CartContext";
import { useContext, useState } from "react";
import Modifiers from "../Modifiers/ModifiersComponent";
import { FiCornerDownLeft } from "react-icons/fi";
import comingSoonImage from "../../../assets/image-coming-soon.jpg";

const FoodItem = (props) => {
    const { item, itemType } = props;
    const [showModifierMenu, setShowModifierMenu] = useState(false);
    const [quantity, setQuantity] = useState(1);

    const { addItemToCart, cartItems } = useContext(CartContext);

    // item.imageUrl != "" ? item.imageUrl : "https://picsum.photos/200";
    const imageUrl = item.imageUrl != "" ? item.imageUrl : comingSoonImage;

    const handleAdd = () => {
        setShowModifierMenu(true);
    };

    const incrementQuantity = () => {
        setQuantity(quantity + 1);
    };

    const decrementQuantity = () => {
        if (quantity != 1) {
            setQuantity(quantity - 1);
        }
    };

    const trimModifiers = (modifiers) => {
        if (isEmpty(modifiers[modifiers.length - 1].name)) {
            modifiers.splice(modifiers.length - 1, 1);
            const newModifiers = [...modifiers];
            return newModifiers;
        }
        return modifiers;
    };

    const submitToCart = (modifiers) => {
        let trimmedModifiers = trimModifiers(modifiers);
        let cartItem = {
            ...item,
            quantity: quantity,
            modifiers: trimmedModifiers,
        };
        addItemToCart(cartItem);
        setShowModifierMenu(false);
    };

    const isEmpty = (modifiers) => {
        if (!modifiers) {
            return true;
        }
        return false;
    };

    const closeModifierWindow = () => {
        setShowModifierMenu(false);
    };

    return (
        <div className="app--foodItemContainer">
            {showModifierMenu && (
                <Modifiers
                    submitToCart={submitToCart}
                    closeModifierWindow={closeModifierWindow}
                    item={item}
                    itemType={itemType}
                    incrementQuantity={incrementQuantity}
                    decrementQuantity={decrementQuantity}
                    quantity={quantity}
                    specificModifiers={item.modifiers}
                />
            )}

            <div>
                <img
                    alt="foodItem-image"
                    src={imageUrl}
                    className="app--foodItemImage"
                ></img>
                <div>
                    <div className="foodItem--interactable-smallScreen">
                        <div className="app--foodItem-price p--opensans">
                            <div className="foodItem-priceValue">
                                ${item.price}
                            </div>
                            <FoodItemQuantitySelector
                                incrementQuantity={incrementQuantity}
                                decrementQuantity={decrementQuantity}
                                quantity={quantity}
                            />
                        </div>
                        <div className="app--foodItem-orderButtonCointainer">
                            <button
                                className="custom--button app--foodItem-orderButton"
                                onClick={() => {
                                    handleAdd();
                                }}
                            >
                                Add
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="foodItem-description-container">
                <h4 className="p--cormorant foodItem--title">{item.name}</h4>
                <a className="p--opensans a--foodItemDescription">
                    {item.description}
                </a>
            </div>
            <div className="app--foodItem-interactable">
                <div className="app--foodItem-price p--opensans">
                    ${item.price}
                </div>
                <div className="app--foodItem-orderButtonCointainer">
                    <button
                        className="custom--button app--foodItem-orderButton"
                        onClick={() => {
                            handleAdd();
                        }}
                    >
                        Add
                    </button>
                </div>
                <FoodItemQuantitySelector
                    incrementQuantity={incrementQuantity}
                    decrementQuantity={decrementQuantity}
                    quantity={quantity}
                />
            </div>
        </div>
    );
};

export default FoodItem;
