import CategorySelector from "./CategorySelector/CategorySelectorComponent";
import Category from "./Category/CategoryComponent";
import "./OnlineOrderStyles.css";
import Cart from "./Cart/CartComponent.jsx";
import { useRef, useState } from "react";
import { MdRestaurantMenu } from "react-icons/md";
import { useContext } from "react";
import { MenuItemsContext } from "../../contexts/MenuItemsContext";
import Checkout from "./Checkout/CheckoutComponent";
import {
    isTodayAWeekend,
    isItLunchTime,
    getCentralTime,
} from "../../utilities/utilities";

const OnlineOrder = () => {
    const { menuItems, filterMenuItems } = useContext(MenuItemsContext);

    const [showCartView, setShowCartView] = useState(false);
    const [showCategorySelectorView, setShowCategorySelectorCartView] =
        useState(false);

    const [showCheckout, setShowCheckout] = useState(false);

    const closeCheckout = () => {
        setShowCheckout(false);
    };

    const categoryRefs = useRef({});
    const scrollContainerRef = useRef(null);

    const scrollToCategory = (key) => {
        if (categoryRefs.current[key.category] == null) {
            setShowCategorySelectorCartView(false);
            return;
        }
        scrollContainerRef.current.scrollTop =
            categoryRefs.current[key.category].offsetTop - 180;

        setShowCategorySelectorCartView(false);
    };

    const filterFoodItems = (event) => {
        filterMenuItems(event);
    };

    const isEmpty = () => {
        for (var i = 0; i < menuItems.length; i++) {
            if (menuItems[i].items.length > 0) {
                return false;
            }
        }

        return true;
    };

    const shouldRenderAtThisTime = (categoryName) => {
        const category = menuItems[categoryName];

        if (category.isLunchCategory === true) {
            // console.log("isLunchCategory", category.categoryName);
            // console.log("isTodayAWeekend: ", isTodayAWeekend());
            // console.log("isItLunchTime: ", isItLunchTime());

            if (isItLunchTime() === true && isTodayAWeekend() === false) {
                return true;
            }
            return false;
        }
        return true;
    };

    console.log("Online Order Component: ", getCentralTime());

    return (
        <div className="app--onlineOrderPage app--background">
            <div className="app--onlineOrder-mainContainer section--padding">
                <div className="app--onlineOrder-content">
                    <div className="app--onlineOrder-categories app--scrollable">
                        <CategorySelector
                            menuItems={menuItems}
                            callback={scrollToCategory}
                            shouldRenderAtThisTime={shouldRenderAtThisTime}
                        />
                    </div>
                    <div className="app--onlineOrder-divider"></div>
                    <div
                        className="app--onlineOrder-categoriesContainer app--scrollable"
                        ref={scrollContainerRef}
                    >
                        <div className="app--search-input">
                            <input
                                placeholder="Search..."
                                onChange={(event) => filterFoodItems(event)}
                            ></input>
                        </div>
                        {!isEmpty() ? (
                            Object.keys(menuItems).map((category, index) => {
                                if (shouldRenderAtThisTime(category)) {
                                    return (
                                        <div key={"category" + index}>
                                            <Category
                                                refId={category}
                                                categoryName={
                                                    menuItems[category]
                                                        .categoryName
                                                }
                                                items={Object.values(
                                                    menuItems[category].items
                                                )}
                                                categoryRefs={categoryRefs}
                                            />
                                        </div>
                                    );
                                }
                            })
                        ) : (
                            <div>
                                <p className="p--opensans">
                                    no items match the search criteria, please
                                    refine your search
                                </p>
                            </div>
                        )}
                    </div>

                    <div className="app--onlineOrder-divider"></div>

                    <div className="app--onlineOrder-cartContainer app--scrollable">
                        <Cart
                            showCheckout={showCheckout}
                            closeCheckout={closeCheckout}
                            setShowCheckout={setShowCheckout}
                        />
                    </div>
                    <div className="onlineOrder-buttonContainer-smallScreen">
                        <div className="onlineOrder-categoriesButton-smallScreen">
                            <button
                                className="onlineOrder-floatingButton"
                                role="button"
                                onClick={() =>
                                    setShowCategorySelectorCartView(true)
                                }
                            >
                                Categories
                            </button>
                        </div>
                        <div className="onlineOrder-cartButton-smallScreen">
                            <button
                                className="onlineOrder-floatingButton"
                                role="button"
                                onClick={() => setShowCartView(true)}
                            >
                                Cart
                            </button>
                        </div>
                    </div>

                    {showCartView && (
                        <div className="cart-smallscreen-overlay flex--center slide-top ">
                            <MdRestaurantMenu
                                fontSize={27}
                                className="overlay--close"
                                onClick={() => setShowCartView(false)}
                            />

                            <Cart
                                showCheckout={showCheckout}
                                closeCheckout={closeCheckout}
                                setShowCheckout={setShowCheckout}
                            />
                        </div>
                    )}

                    {showCategorySelectorView && (
                        <div className="cart-smallscreen-overlay flex--center slide-top">
                            <MdRestaurantMenu
                                fontSize={27}
                                className="overlay--close"
                                onClick={() =>
                                    setShowCategorySelectorCartView(false)
                                }
                            />

                            <CategorySelector
                                menuItems={menuItems}
                                callback={scrollToCategory}
                                shouldRenderAtThisTime={shouldRenderAtThisTime}
                            />
                        </div>
                    )}
                    {showCheckout && <Checkout closeCheckout={closeCheckout} />}
                </div>
            </div>
        </div>
    );
};

export default OnlineOrder;
