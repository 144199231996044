import "./SpecialMenuStyles.css";
import SubHeading from "../../components/SubHeading/SubHeadingComponent.jsx";
import MenuItem from "../../components/MenuItem/MenuItemComponent";
import { images, data } from "../../constants";
import SlideShow from "./SlideShow/SlideShowComponent";

const SpecialMenu = () => {
    return (
        <div
            id="menu"
            className="app--specialMenu flex--center section--padding"
        >
            <div className="app--specialManu-title">
                <SubHeading title="Try Something New" />
                <h1 className="headtext--cormorant">Today's Specials</h1>
            </div>

            <div className="app--specialMenu-menu">
                <div className="app--specialMenu-menu-wine flex--center">
                    <p className="app--specialMenu-menu-heading">
                        Asian Specials
                    </p>
                    <div className="app--specialMenu-menuItems">
                        {data.asianSpecials.map((item, index) => (
                            <MenuItem
                                key={item.title + index}
                                title={item.title}
                                price={item.price}
                                tags={item.tags}
                            />
                        ))}
                    </div>
                </div>

                <SlideShow images={images} />

                <div className="app--specialMenu-menu-cocktails flex--center">
                    <p className="app--specialMenu-menu-heading">Drinks</p>
                    <div className="app--specialMenu-menuItems">
                        {data.drinks.map((item, index) => (
                            <MenuItem
                                key={item.title + index}
                                title={item.title}
                                price={item.price}
                                tags={item.tags}
                            />
                        ))}
                    </div>
                </div>
            </div>

            <div style={{ marginTop: "15px" }}>
                <a href="/menu">
                    <button type="button" className="custom--button">
                        View Full Menu
                    </button>
                </a>
            </div>
        </div>
    );
};

export default SpecialMenu;
