const ConfirmationItem = (props) => {
    const { item } = props;
    let modifierTotal = 0;
    return (
        <tr>
            <td>
                <div>
                    <h3>{item.name}</h3>
                </div>
                <div>
                    {item.modifiers &&
                        item.modifiers.map((modifier, index) => {
                            modifierTotal += modifier.price;
                            return (
                                <div
                                    key={modifier.name + index}
                                    className="cartItem-modifier-container"
                                >
                                    <div className="cartItem-modifier-name">
                                        <a className="modifier-spacer">
                                            &nbsp; &nbsp;
                                        </a>
                                        <a
                                            className="p--opensans a--foodItemDescription cartItem-modifier-name"
                                            key={modifier.name + index}
                                        >
                                            &#8226; {modifier.name}
                                        </a>
                                    </div>
                                    <div>
                                        <a>
                                            {modifier.price == 0.0 ? (
                                                <></>
                                            ) : (
                                                "$" + modifier.price.toFixed(2)
                                            )}
                                        </a>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </td>
            <td className="confirmationItem-quantity">{item.quantity}</td>
            <td className="confirmationItem-price">
                ${((item.price + modifierTotal) * item.quantity).toFixed(2)}
            </td>
        </tr>
    );
};

export default ConfirmationItem;
