const modifications = [
    {
        categoryName: "Appetizers",
        modifiers: [],
    },
    {
        categoryName: "Sauces",
        modifiers: [
            {
                name: [
                    "Yum Yum Sauce",
                    "Duck Sauce",
                    "Eel Sauce",
                    "Teriyaki Sauce",
                    "Sweet n Sour Sauce",
                    "Ginger Dressing",
                    "Ponzu Sauce (Dumpling Sauce)",
                    "Sriracha",
                    "Hibachi Sauce",
                    "Hot Mustard",
                ],
                price: 0.0,
            },
        ],
    },
    {
        categoryName: "Combination Dinner",
        modifiers: [
            {
                name: ["No Salad, Double Soup", "No Soup, Double Salad"],
                price: 0.0,
            },
            {
                name: "No Vegetables",
                price: 0.0,
            },
            {
                name: "Add Vegetables (specify below)",
                price: 1.0,
            },
            {
                name: "Add Mixed Vegetables",
                price: 2.0,
            },
        ],
    },
    {
        categoryName: "Dinner Entrees",
        modifiers: [
            {
                name: ["No Salad, Double Soup", "No Soup, Double Salad"],
                price: 0.0,
            },
            {
                name: "No Vegetables",
                price: 0.0,
            },
            {
                name: "Add Vegetables (specify below)",
                price: 1.0,
            },
            {
                name: "Add Mixed Vegetables",
                price: 2.0,
            },
        ],
    },
    {
        categoryName: "Special Rolls",
        modifiers: [
            {
                name: "Add Cream Cheese",
                price: 1.0,
            },
            {
                name: "Add Avocado",
                price: 1.0,
            },
            {
                name: "Add Spicy Mayo",
                price: 0.0,
            },
            {
                name: "Add Eel Sauce",
                price: 0.0,
            },
            {
                name: "Add Salmon",
                price: 3.99,
            },
            {
                name: "Deep Fry",
                price: 2.0,
            },
        ],
    },
    {
        categoryName: "Poke Bowl/Sushi Burrito",
        modifiers: [
            {
                name: "Testing Testing 123, This block of code should be unreachable",
                price: 1.0,
                label: "Base",
            },
            {
                name: ["Fried Rice", "Sushi Rice", "Salad"],
                price: 0.0,
            },
            {
                name: "Testing Testing 123, This block of code should be unreachable",
                price: 1.0,
                label: "1st Protein",
            },
            {
                name: [
                    "Chicken",
                    "Crab Meat",
                    "Eel",
                    "Salmon*",
                    "Shrimp",
                    "Spicy Crab",
                    "Spicy Salmon*",
                    "Spicy Shrimp",
                    "Spicy Tuna",
                    "Tuna",
                ],
                price: 0.0,
            },
            {
                name: "Testing Testing 123, This block of code should be unreachable",
                price: 1.0,
                label: "2nd Protein",
            },
            {
                name: [
                    "Chicken ",
                    "Crab Meat ",
                    "Eel ",
                    "Salmon* ",
                    "Shrimp ",
                    "Spicy Crab ",
                    "Spicy Salmon* ",
                    "Spicy Shrimp ",
                    "Spicy Tuna ",
                    "Tuna ",
                ],
                price: 0.0,
            },
            {
                name: "Testing Testing 123, This block of code should be unreachable",
                price: 1.0,
                label: "3rd Protein",
            },
            {
                name: [
                    "Chicken  ",
                    "Crab Meat  ",
                    "Eel  ",
                    "Salmon*  ",
                    "Shrimp  ",
                    "Spicy Crab  ",
                    "Spicy Salmon*  ",
                    "Spicy Shrimp  ",
                    "Spicy Tuna  ",
                    "Tuna  ",
                ],
                price: 1.0,
            },
            {
                name: "Testing Testing 123, This block of code should be unreachable",
                price: 1.0,
                label: "Sauce",
            },
            {
                name: "Eel Sauce",
                price: 0.0,
            },
            {
                name: "Ginger Dressing",
                price: 0,
            },
            {
                name: "Siracha Aioli",
                price: 0,
            },
            {
                name: "Spicy Mayo",
                price: 0,
            },
            {
                name: "Wasabi Aioli",
                price: 0,
            },
            {
                name: "YumYum Sauce",
                price: 0,
            },
            {
                name: "Testing Testing 123, This block of code should be unreachable",
                price: 1.0,
                label: "Mix In",
            },
            {
                name: "Cucumber",
                price: 0.0,
            },
            {
                name: "White Onion",
                price: 0,
            },
            {
                name: "Pickled Radish",
                price: 0,
            },
            {
                name: "Avocado",
                price: 0,
            },
            {
                name: "Cream Cheese",
                price: 0,
            },
            {
                name: "Bell Pepper",
                price: 0,
            },
            {
                name: "Mushroom",
                price: 0,
            },
            {
                name: "Jalapeno",
                price: 0,
            },
            {
                name: "Mango",
                price: 0,
            },
            {
                name: "Red Cabbage",
                price: 0,
            },
            {
                name: "Lettuce",
                price: 0,
            },
            {
                name: "Edamame",
                price: 0,
            },
            {
                name: "Testing Testing 123, This block of code should be unreachable",
                price: 1.0,
                label: "Topping",
            },
            {
                name: "Green Onions",
                price: 0.0,
            },
            {
                name: "Masago",
                price: 0,
            },
            {
                name: "Pickled Ginger",
                price: 0,
            },
            {
                name: "Seaweed Salad",
                price: 0,
            },
            {
                name: "Sesame Seeds",
                price: 0,
            },
            {
                name: "Wasabi",
                price: 0,
            },
            {
                name: "Testing Testing 123, This block of code should be unreachable",
                price: 1.0,
                label: "Crunch",
            },
            {
                name: "Garlic Crisps",
                price: 0.0,
            },
            {
                name: "Onion Crisps",
                price: 0,
            },
            {
                name: "Shredded Nori",
                price: 0,
            },
        ],
    },
    {
        categoryName: "Sushi Rolls",
        modifiers: [
            {
                name: "Add Cream Cheese",
                price: 1.0,
            },
            {
                name: "Add Avocado",
                price: 1.0,
            },
            {
                name: "Add Spicy Mayo",
                price: 0.0,
            },
            {
                name: "Add Eel Sauce",
                price: 0.0,
            },
            {
                name: "Add Salmon",
                price: 3.99,
            },
            {
                name: "Deep Fry",
                price: 2.0,
            },
        ],
    },
    {
        categoryName: "Sushi Lunch Entrees",
        modifiers: [
            {
                name: "Exchange Salad for a Miso Soup",
                price: 0.0,
            },
        ],
    },
    {
        categoryName: "Sushi Bar Entrees",
        modifiers: [
            {
                name: [
                    "Exchange Salad for double Miso Soup",
                    "Exchange Miso Soup for double Salad",
                ],
                price: 0.0,
            },
        ],
    },
    {
        categoryName: "Lunch Entrees",
        modifiers: [
            {
                name: "Exchange Salad for a Miso Soup",
                price: 0.0,
            },
            {
                name: "No Vegetables",
                price: 0.0,
            },
            {
                name: "Add Vegetables (specify below)",
                price: 1.0,
            },
            {
                name: "Add Mixed Vegetables",
                price: 2.0,
            },
        ],
    },
    {
        categoryName: "Teriyaki Rice Bowl",
        modifiers: [
            {
                name: "No Vegetables",
                price: 0.0,
            },
            {
                name: "Add Vegetables (specify below)",
                price: 1.0,
            },
            {
                name: "Add Mixed Vegetables",
                price: 2.0,
            },
        ],
    },
    {
        categoryName: "Soba",
        modifiers: [
            {
                name: "No Vegetables",
                price: 0.0,
            },
            {
                name: "Add Vegetables (specify below)",
                price: 1.0,
            },
            {
                name: "Add Mixed Vegetables",
                price: 2.0,
            },
        ],
    },
    {
        categoryName: "Asian Specials",
        modifiers: [
            {
                name: "No Vegetables",
                price: 0.0,
            },
            {
                name: "Add Vegetables (specify below)",
                price: 1.0,
            },
            {
                name: "Add Mixed Vegetables",
                price: 2.0,
            },
        ],
    },
    {
        categoryName: "Bento Boxes",
        modifiers: [
            {
                name: ["No Salad, Double Soup", "No Soup, Double Salad"],
                price: 0.0,
            },
            {
                name: "Grilled Vegetables instead of fried",
                price: 0.0,
            },
            {
                name: "No Sushi",
                price: 0.0,
            },
            {
                name: "No Sushi, Extra Spring Roll",
                price: 0.0,
            },
        ],
    },
    {
        categoryName: "Kids Menu",
        modifiers: [
            {
                name: "No Vegetables",
                price: 0.0,
            },
            {
                name: "Add Vegetables (specify below)",
                price: 1.0,
            },
            {
                name: "Add Mixed Vegetables",
                price: 2.0,
            },
        ],
    },
    {
        categoryName: "Side Orders",
        modifiers: [
            {
                name: "No Vegetables",
                price: 0.0,
            },
        ],
    },
    {
        categoryName: "Drinks",
        modifiers: [
            {
                name: "Lite Ice",
                price: 0.0,
            },
            {
                name: "Testing Testing 123, This block of code should be unreachable",
                price: 1.0,
                label: "Select your drink",
            },
            {
                name: [
                    "Coke",
                    "Sprite",
                    "Lemonade",
                    "Diet Coke",
                    "Dr Pepper",
                    "Powerade",
                    "Fanta Orange",
                ],
                price: 0.0,
            },
        ],
    },
    {
        categoryName: "Nigiri Sushi or Sashimi",
        modifiers: [
            {
                name: "Testing Testing 123, This block of code should be unreachable",
                price: 1.0,
                label: "First Piece",
            },
            {
                name: [
                    "Salmon",
                    "Red Tuna",
                    "White Tuna",
                    "Yellow Tail",
                    "Red Snapper",
                    "Octopus",
                    "Eel",
                    "Crab",
                    "Shrimp",
                ],
                price: 0.0,
            },
            {
                name: "Testing Testing 123, This block of code should be unreachable",
                price: 1.0,
                label: "Second Piece",
            },
            {
                name: [
                    "Salmon ",
                    "Red Tuna ",
                    "White Tuna ",
                    "Yellow Tail ",
                    "Red Snapper ",
                    "Octopus ",
                    "Eel ",
                    "Crab ",
                    "Shrimp ",
                ],
                price: 0.0,
            },
        ],
    },
    {
        categoryName: "Boba Milk Teas",
        modifiers: [
            {
                name: "Testing Testing 123, This block of code should be unreachable",
                price: 1.0,
                label: "Flavor",
            },
            {
                name: [
                    "Thai Tea",
                    "Mango",
                    "Strawberry",
                    "Taro",
                    "Milk Tea",
                    "Honewdew",
                    "Brown Sugar Milk Tea",
                    "Matcha Green Tea",
                    "Mocha Iced Coffee",
                    "Peach",
                    "Cappuccino",
                ],
                price: 0.0,
            },
            {
                name: "Testing Testing 123, This block of code should be unreachable",
                price: 1.0,
                label: "Toppings",
            },
            {
                name: "Tapioca Pearls (Boba)",
                price: 1.0,
            },
            {
                name: "Lychee Jelly",
                price: 1.0,
            },
            {
                name: "Boba Jelly",
                price: 1.0,
            },
            {
                name: "Rainbow Jelly",
                price: 1.0,
            },
            {
                name: "Popping Boba Apple",
                price: 1.0,
            },
            {
                name: "Popping Boba Strawberry",
                price: 1.0,
            },
            {
                name: "Popping Boba Passion Fruit",
                price: 1.0,
            },
            {
                name: "Popping Boba Mango",
                price: 1.0,
            },
        ],
    },
    {
        categoryName: "Boba Fruit Teas",
        modifiers: [
            {
                name: "Testing Testing 123, This block of code should be unreachable",
                price: 1.0,
                label: "Flavor",
            },
            {
                name: [
                    "Passion Fruit",
                    "Mango",
                    "Strawberry",
                    "Pineapple",
                    "Peach",
                    "Kiwi",
                    "Pomegranate",
                    "Blueberry",
                    "Watermelon",
                ],
                price: 0.0,
            },
            {
                name: "Testing Testing 123, This block of code should be unreachable",
                price: 1.0,
                label: "Toppings",
            },
            {
                name: "Tapioca Pearls (Boba)",
                price: 1.0,
            },
            {
                name: "Lychee Jelly",
                price: 1.0,
            },
            {
                name: "Boba Jelly",
                price: 1.0,
            },
            {
                name: "Rainbow Jelly",
                price: 1.0,
            },
            {
                name: "Popping Boba Apple",
                price: 1.0,
            },
            {
                name: "Popping Boba Strawberry",
                price: 1.0,
            },
            {
                name: "Popping Boba Passion Fruit",
                price: 1.0,
            },
            {
                name: "Popping Boba Mango",
                price: 1.0,
            },
        ],
    },
];

export { modifications };
