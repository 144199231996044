import SubHeading from "../../components/SubHeading/SubHeadingComponent.jsx";
import { images } from "../../constants";
import {
    BsInstagram,
    BsFacebook,
    BsArrowLeftShort,
    BsArrowRightShort,
} from "react-icons/bs";
import "./GalleryStyles.css";
import { useRef } from "react";
// import path from "./images";

// const galleryImages = [
//     images.gallery01,
//     images.gallery02,
//     images.gallery03,
//     images.gallery04,
// ];

const Gallery = () => {
    const scrollRef = useRef(null);

    const galleryImages = importAll(
        require.context("./images", false, /\.(jpg)$/)
    );

    function importAll(r) {
        let images = {};
        r.keys().map((item, index) => {
            images[item.replace("./", "")] = r(item);
        });
        return images;
    }

    const scroll = (direction) => {
        const { current } = scrollRef;
        if (direction === "left") {
            current.scrollLeft -= 300;
        } else {
            current.scrollLeft += 300;
        }
    };

    return (
        <div className="app--gallery flex--center app--background">
            <div className="app--gallery-content">
                <SubHeading title="Facebook" />
                <h1 className="headtext--cormorant">Photo Gallery</h1>
                <p
                    className="p--opensans"
                    style={{ color: "#AAA", marginTop: "2rem" }}
                >
                    Here, you'll find a tantalizing display of our most
                    delicious and beautifully presented dishes. From classic
                    Japanese staples to bold and exotic creations, our menu
                    offers something for every palate. Our commitment to using
                    only the freshest ingredients and preparing each dish to
                    perfection is evident in every photo, and we're confident
                    that our food will leave you craving for more. So sit back,
                    relax, and feast your eyes on our stunning collection of
                    culinary delights. Don't forget to follow us on social media
                    for even more appetizing photos and updates!
                </p>
                <a href="https://www.facebook.com/yamatojacksonville">
                    <button type="button" className="custom--button">
                        View More
                    </button>
                </a>
            </div>
            <div className="app--gallery-images">
                <div className="app--gallery-images-container" ref={scrollRef}>
                    {galleryImages &&
                        Object.keys(galleryImages).map((key, index) => {
                            let image = galleryImages[key];
                            return (
                                <div
                                    className="app--gallery-images-card flex--center"
                                    key={"gallery_image-" + index + 1}
                                >
                                    <img src={image} alt="gallery" />
                                    {/* <BsFacebook
                                        className="gallery--image-icon"
                                        href="https://www.facebook.com/yamatojacksonville"
                                    /> */}
                                </div>
                            );
                        })}
                </div>
                <div className="app--gallery-images-arrow">
                    <BsArrowLeftShort
                        className="gallery--arrow-icon"
                        onClick={() => {
                            scroll("left");
                        }}
                    />
                    <BsArrowRightShort
                        className="gallery--arrow-icon"
                        onClick={() => {
                            scroll("right");
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default Gallery;
