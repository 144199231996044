// const order = {
//     items: [...cartItems],
//     customerInfo: customer,
//     subTotal: subTotal,
//     total: total,
//     specialInstructions: specialInstructions,
//     pickupTime: pickupTime,
//     dateTime: date.toDateString(),
//     orderNumber: orderNumber,
// };

const buildEmail = (orderData) => {
    let html = `

    <!DOCTYPE html>
    <html>
        <head>
            <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
            <title>Yamato Steakhouse of Japan</title>
        </head>
        <body
            leftmargin="0"
            marginwidth="0"
            topmargin="0"
            marginheight="0"
            offset="0"
            style="
                background-color: #f6f6f6;
                font-family: 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande',
                    sans-serif;
            "
        >
            <div
                style="
                    width: 100%;
                    -webkit-text-size-adjust: none !important;
                    margin: 0;
                    padding: 70px 0 70px 0;
                "
            >
                <table
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    height="100%"
                    width="100%"
                >
                    <tr>
                        <td align="center" valign="top">
                            <table
                                border="0"
                                cellpadding="0"
                                cellspacing="0"
                                width="520"
                                id="template_container"
                                style="
                                    box-shadow: 0 0 0 1px #f3f3f3 !important;
                                    border-radius: 3px !important;
                                    background-color: #ffffff;
                                    border: 1px solid #e9e9e9;
                                    border-radius: 3px !important;
                                    padding: 20px;
                                "
                            >
                                <tr>
                                    <td align="center" valign="top">
                                        <!-- Header -->
                                        <table
                                            border="0"
                                            cellpadding="0"
                                            cellspacing="0"
                                            width="520"
                                            id="template_header"
                                            style="
                                                color: #00000;
                                                border-top-left-radius: 3px !important;
                                                border-top-right-radius: 3px !important;
                                                border-bottom: 0;
                                                font-weight: bold;
                                                line-height: 100%;
                                                text-align: center;
                                                vertical-align: middle;
                                            "
                                            bgcolor="#ffffff"
                                        >
                                            <tr>
                                                <td>
                                                    <h1
                                                        style="
                                                            color: #000000;
                                                            margin: 0;
                                                            padding: 28px 24px;
                                                            display: block;
                                                            font-family: 'Helvetica Neue',
                                                                Helvetica, Arial,
                                                                'Lucida Grande',
                                                                sans-serif;
                                                            font-size: 32px;
                                                            font-weight: 500;
                                                            line-height: 1.2;
                                                        "
                                                    >
                                                        Online Order
                                                    </h1>
                                                </td>
                                            </tr>
                                        </table>
                                        <!-- End Header -->
                                    </td>
                                </tr>
                                <tr>
                                    <td align="center" valign="top">
                                        <!-- Body -->
                                        <table
                                            border="0"
                                            cellpadding="0"
                                            cellspacing="0"
                                            width="520"
                                            id="template_body"
                                        >
                                            <tr>
                                                <td
                                                    valign="top"
                                                    style="
                                                        border-radius: 3px !important;
                                                        font-family: 'Helvetica Neue',
                                                            Helvetica, Arial,
                                                            'Lucida Grande',
                                                            sans-serif;
                                                    "
                                                >
                                                    <!-- Content -->
                                                    <table
                                                        border="0"
                                                        cellpadding="20"
                                                        cellspacing="0"
                                                        width="100%"
                                                    >
                                                        <tr>
                                                            <td valign="top">
                                                                <div
                                                                    style="
                                                                        color: #000000;
                                                                        font-size: 14px;
                                                                        font-family: 'Helvetica Neue',
                                                                            Helvetica,
                                                                            Arial,
                                                                            'Lucida Grande',
                                                                            sans-serif;
                                                                        line-height: 150%;
                                                                        text-align: left;
                                                                    "
                                                                >
                                                                    <table
                                                                        id="email-table"
                                                                        class="display responsive no-wrap order-column"
                                                                        width="100%"
                                                                        style="
                                                                            border: 1px
                                                                                solid
                                                                                black;
                                                                            border-collapse: collapse;
                                                                        "
                                                                    >
                                                                        <thead>
                                                                            <tr>
                                                                                <th
                                                                                    style="
                                                                                        border: 1px
                                                                                            solid
                                                                                            black;
                                                                                        border-collapse: collapse;
                                                                                    "
                                                                                >
                                                                                    Food
                                                                                    Item
                                                                                    Purchased
                                                                                </th>
                                                                                <th
                                                                                    class="center"
                                                                                    style="
                                                                                        text-align: center;
                                                                                        border: 1px
                                                                                            solid
                                                                                            black;
                                                                                        border-collapse: collapse;
                                                                                    "
                                                                                >
                                                                                    Quantity
                                                                                </th>
                                                                                <th
                                                                                    class="center"
                                                                                    style="
                                                                                        text-align: center;
                                                                                        border: 1px
                                                                                            solid
                                                                                            black;
                                                                                        border-collapse: collapse;
                                                                                    "
                                                                                >
                                                                                    Price
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>`;

    orderData.items.map((foodItem) => {
        html += `<tr>`;
        html += buildEntryItem(foodItem);
        html += buildEntryQuantity(foodItem);
        html += buildEntryPrices(foodItem);
        html += `</tr>`;
    });

    html += `</tbody>
                                                                        <tfoot>
                                                                            <tr>
                                                                                <td
                                                                                    colspan="2"
                                                                                    class="right"
                                                                                    style="
                                                                                        border: 1px
                                                                                            solid
                                                                                            black;
                                                                                        border-collapse: collapse;
                                                                                        text-align: right;
                                                                                        padding-right: 10px;
                                                                                        border-top: none;
                                                                                        border-bottom: none;
                                                                                    "
                                                                                >
                                                                                    <strong
                                                                                        >Sub
                                                                                        Total:</strong
                                                                                    >
                                                                                </td>
                                                                                <td
                                                                                    class="right"
                                                                                    style="
                                                                                        border: 1px
                                                                                            solid
                                                                                            black;
                                                                                        border-collapse: collapse;
                                                                                        text-align: center;
                                                                                        border-top: none;
                                                                                        border-bottom: none;
                                                                                    "
                                                                                >
                                                                                    <span
                                                                                        >&#36;`;
    html += orderData.subTotal.toFixed(2);
    html += `</span
                                                                                    >
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td
                                                                                    colspan="2"
                                                                                    class="right"
                                                                                    style="
                                                                                        border: 1px
                                                                                            solid
                                                                                            black;
                                                                                        border-collapse: collapse;
                                                                                        text-align: right;
                                                                                        padding-right: 10px;
                                                                                        border-top: none;
                                                                                        border-bottom: none;
                                                                                    "
                                                                                >
                                                                                    <strong
                                                                                        >Estimated
                                                                                        Tax:</strong
                                                                                    >
                                                                                </td>
                                                                                <td
                                                                                    class="right"
                                                                                    style="
                                                                                        border: 1px
                                                                                            solid
                                                                                            black;
                                                                                        border-collapse: collapse;
                                                                                        text-align: center;
                                                                                        border-top: none;
                                                                                        border-bottom: none;
                                                                                    "
                                                                                >
                                                                                    <span
                                                                                        >&#36;`;
    html += (orderData.subTotal * 0.1).toFixed(2);
    html += `</span>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td
                                                                                    colspan="2"
                                                                                    class="right"
                                                                                    style="
                                                                                        border: 1px
                                                                                            solid
                                                                                            black;
                                                                                        border-collapse: collapse;
                                                                                        text-align: right;
                                                                                        padding-right: 10px;
                                                                                        border-top: none;
                                                                                    "
                                                                                >
                                                                                    <strong
                                                                                        >Total:</strong
                                                                                    >
                                                                                </td>
                                                                                <td
                                                                                    class="right"
                                                                                    style="
                                                                                        border: 1px
                                                                                            solid
                                                                                            black;
                                                                                        border-collapse: collapse;
                                                                                        text-align: center;
                                                                                        border-top: none;
                                                                                    "
                                                                                >
                                                                                    <span
                                                                                        >&#36;`;
    html += orderData.total;
    html += `</span
                                                                                    >
                                                                                </td>
                                                                            </tr>
                                                                        </tfoot>
                                                                    </table>
                                                                    <p>`;

    html += "OrderNumber: " + orderData.orderNumber;
    html += `<br />`;
    html += orderData.customerInfo.name;
    html += `<br />`;
    html += orderData.customerInfo.phoneNumber;
    html += `<br />
                                                                        <a>`;
    html += orderData.customerInfo.email;
    html += `</a
                                                                        ><br />`;
    html += orderData.dateTime;
    html += `<br />`;
    html += "$" + orderData.total;
    html += `<br />`;
    html += orderData.pickupTime;
    html += ` -
                pick up time `;
    html += `<br />`;
    html += `Special Instructions: ` + orderData.specialInstructions;

    html += ` </p>                                                   </div>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    <!-- End Content -->
                                                </td>
                                            </tr>
                                        </table>
                                        <!-- End Body -->
                                    </td>
                                </tr>
                                <tr>
                                    <td align="center" valign="top">
                                        <!-- Footer -->
                                        <table
                                            border="0"
                                            cellpadding="10"
                                            cellspacing="0"
                                            width="600"
                                            id="template_footer"
                                            style="
                                                border-top: 0;
                                                -webkit-border-radius: 3px;
                                            "
                                        >
                                            <tr>
                                                <td valign="top">
                                                    <table
                                                        border="0"
                                                        cellpadding="10"
                                                        cellspacing="0"
                                                        width="100%"
                                                    >
                                                        <tr>
                                                            <td
                                                                colspan="2"
                                                                valign="middle"
                                                                id="credit"
                                                                style="
                                                                    border: 0;
                                                                    color: #000000;
                                                                    font-family: 'Helvetica Neue',
                                                                        Helvetica,
                                                                        Arial,
                                                                        'Lucida Grande',
                                                                        sans-serif;
                                                                    font-size: 12px;
                                                                    line-height: 125%;
                                                                    text-align: center;
                                                                "
                                                            >
                                                                <p>
                                                                    <a
                                                                        href="http://jacksonvilleyamato.com"
                                                                        >Yamato
                                                                        Steakhouse
                                                                        of Japan</a
                                                                    >
                                                                </p>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                        <!-- End Footer -->
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </div>
        </body>
    </html>

    
    `;

    return html;
};

const buildEntryItem = (foodItem) => {
    let html = `<td
                        style="
                            border: 1px
                                solid
                                black;
                            border-collapse: collapse;
                        "
                    >`;
    html += foodItem.name;

    foodItem.modifiers.map((modifier) => {
        html += `<div
                            style="
                                margin-left: 10px;
                                margin-top: 5px;
                                font-size: smaller;
                                color: #444;
                            "
                        >`;
        html += modifier.name;
        html += `</div>`;
    });

    html += `</td>`;

    return html;
};
const buildEntryQuantity = (foodItem) => {
    let html = `<td
                    class="center"
                    style="
                        border: 1px
                            solid
                            black;
                        border-collapse: collapse;
                        text-align: center;
                    "
                >
                    <span>`;
    html += foodItem.quantity;
    html += `</span>
                </td>`;

    return html;
};

const buildEntryPrices = (foodItem) => {
    let html = `<td
                    class="center"
                    style="
                        border: 1px
                            solid
                            black;
                        border-collapse: collapse;
                        text-align: center;
                    "
                >`;
    html += `<span>&#36;`;
    html += foodItem.price;
    html += `</span>`;

    foodItem.modifiers.map((modifier) => {
        html += `<div
                        style="
                            margin: 0;
                            font-size: 14px;
                        "
                    >`;
        html += modifier.price == 0 ? "" : `&#36;` + modifier.price.toFixed(2);
        html += `</div>`;
    });

    html += `</td>`;
    return html;
};

export default buildEmail;
