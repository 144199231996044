import "./CartItemStyles.css";
import { CartContext } from "../../../contexts/CartContext";
import { useContext } from "react";

const CartItem = (props) => {
    const { item, itemIndex } = props;

    const { removeItemFromCart } = useContext(CartContext);

    const handleRemove = () => {
        removeItemFromCart(itemIndex);
    };

    return (
        <div className="cartItem--container">
            <div className="cartItem--title">
                <div className="cartItem--quantityName">
                    <a className="p--opensans p--quantity">
                        {item.quantity} &#215;
                    </a>
                    <h6 className="p--cormorant">{item.name}</h6>
                </div>
                <div className="cartItem--price">
                    <a className="p--opensans cartItem--price">
                        ${(item.price * item.quantity).toFixed(2)}
                    </a>
                </div>
            </div>

            {item.modifiers &&
                item.modifiers.map((modifier, index) => {
                    return (
                        <div
                            key={modifier.name + index}
                            className="cartItem-modifier-container"
                        >
                            <div className="cartItem-modifier-name">
                                <a className="modifier-spacer">&nbsp; &nbsp;</a>
                                <a
                                    className="p--opensans a--foodItemDescription cartItem-modifier-name"
                                    key={modifier.name + index}
                                >
                                    &#8226; {modifier.name}
                                </a>
                            </div>
                            <div>
                                <a>
                                    {modifier.price == 0.0 ? (
                                        <></>
                                    ) : (
                                        "$" + modifier.price.toFixed(2)
                                    )}
                                </a>
                            </div>
                        </div>
                    );
                })}

            <div className="cartItem--interactableContainer">
                {/* <a className="cartItem--interactableItem">edit</a> */}
                <a
                    className="cartItem--interactableItem"
                    onClick={handleRemove}
                >
                    remove
                </a>
            </div>
        </div>
    );
};

export default CartItem;
