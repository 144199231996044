import "./CategoryHeadingStyles.css";
import { images } from "../../constants";

const CategoryHeading = (props, ref) => {
    const { title } = props;

    return (
        <div style={{ marginBottom: "1rem" }}>
            <p className="p--cormorant category-title">{title}</p>
            <img src={images.spoon} alt="spoon" className="spoon--img" />
        </div>
    );
};

export default CategoryHeading;
