import images from "./images";

const wines = [
    {
        title: "Chapel Hill Shiraz",
        price: "$56",
        tags: "AU | Bottle",
    },
    {
        title: "Catena Malbee",
        price: "$59",
        tags: "AU | Bottle",
    },
    {
        title: "La Vieillw Rose",
        price: "$44",
        tags: "FR | 750 ml",
    },
    {
        title: "Rhino Pale Ale",
        price: "$31",
        tags: "CA | 750 ml",
    },
    {
        title: "Irish Guinness",
        price: "$26",
        tags: "IE | 750 ml",
    },
];

const cocktails = [
    {
        title: "Aperol Sprtiz",
        price: "$20",
        tags: "Aperol | Villa Marchesi prosecco | soda | 30 ml",
    },
    {
        title: "Dark 'N' Stormy",
        price: "$16",
        tags: "Dark rum | Ginger beer | Slice of lime",
    },
    {
        title: "Daiquiri",
        price: "$10",
        tags: "Rum | Citrus juice | Sugar",
    },
    {
        title: "Old Fashioned",
        price: "$31",
        tags: "Bourbon | Brown sugar | Angostura Bitters",
    },
    {
        title: "Negroni",
        price: "$26",
        tags: "Gin | Sweet Vermouth | Campari | Orange garnish",
    },
];

const awards = [
    {
        imgUrl: images.award02,
        title: "Bib Gourmond",
        subtitle: "Lorem ipsum dolor sit amet, consectetur.",
    },
    {
        imgUrl: images.award01,
        title: "Rising Star",
        subtitle: "Lorem ipsum dolor sit amet, consectetur.",
    },
    {
        imgUrl: images.award05,
        title: "AA Hospitality",
        subtitle: "Lorem ipsum dolor sit amet, consectetur.",
    },
    {
        imgUrl: images.award03,
        title: "Outstanding Chef",
        subtitle: "Lorem ipsum dolor sit amet, consectetur.",
    },
];

const asianSpecials = [
    {
        title: "Monkey Brain",
        price: "$9",
        tags: "Spicy Salmon, Spicy Crab, stuffed in a whole avocado then deep fried",
    },
    {
        title: "Beef Teriyaki Roll",
        price: "$14",
        tags: "Beef Teriyaki with Green Onions and Cucimber, served with Eel Sauce ",
    },
    {
        title: "Brown Sugar Milk Tea",
        price: "$5",
        tags: "Classic Hong Kong inspired milk tea with Boba",
    },
    {
        title: "Haru Maki",
        price: "$8",
        tags: "Spicy Crab and Avocado wrapped in a spring roll shell, Fried to perfection",
    },
    {
        title: "JSU Roll",
        price: "$13",
        tags: "Spicy Crab, with Cream Cheese, Deep Fried to perfection",
    },
];

const drinks = [
    {
        title: "Yamato Mai Thai",
        price: "$9",
        tags: "Bacardi Rum, Triple Sec, Pineapple Juice, Orange Juice, Grenadine",
    },
    {
        title: "Vodka Boba Fruit Tea",
        price: "$10",
        tags: "Your Choice of Any Fruit Tea with Vodka and Bursting Boba",
    },
    {
        title: "Strawberry Lemonade",
        price: "$9",
        tags: "Strawberry Rum with Lemonade",
    },
    {
        title: "Sea Breeze",
        price: "$9",
        tags: "Grey Goose Vodka with Cranberry Juice and Pineapple Juice",
    },
    {
        title: "Long Island Ice Tea",
        price: "$9",
        tags: "Vodka, Rum, Tequila, Gin, Triple Sec, Simple Syrup, Lemon Juice, and Coke",
    },
];

export default { wines, cocktails, awards, asianSpecials, drinks };
