import { useContext, useEffect } from "react";
import "./TotalsStyles.css";
import { CartContext } from "../../../contexts/CartContext";

const Totals = () => {
    const { subTotal } = useContext(CartContext);

    const taxRate = 0.1;

    return (
        <div>
            <div className="cart--totalItem">
                <div>Sub Total</div>
                <div>${subTotal.toFixed(2)}</div>
            </div>
            <div className="cart--totalItem">
                <div>Tax</div>
                <div>${(subTotal * taxRate).toFixed(2)}</div>
            </div>
            <div className="cart--totalItem">
                <div>Total</div>
                <div>${(subTotal + subTotal * taxRate).toFixed(2)}</div>
            </div>
        </div>
    );
};

export default Totals;
