const ConfirmationTotal = (props) => {
    const { subTotal } = props;
    const taxRate = 0.1;

    return (
        <tfoot>
            <tr>
                <td colSpan={2} className="totals-label">
                    <strong>Sub Total:</strong>
                </td>
                <td className="totals-value">
                    <span>${subTotal.toFixed(2)}</span>
                </td>
            </tr>
            <tr>
                <td colSpan={2} className="totals-label">
                    <strong>Estimated Tax:</strong>
                </td>
                <td className="totals-value">
                    <span>${(subTotal * taxRate).toFixed(2)} </span>
                </td>
            </tr>
            <tr>
                <td colSpan={2} className="totals-label-last">
                    <strong>Total:</strong>
                </td>
                <td className="totals-value-last">
                    <span>${(subTotal + subTotal * taxRate).toFixed(2)}</span>
                </td>
            </tr>
        </tfoot>
    );
};
export default ConfirmationTotal;
