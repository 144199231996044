import "./CategorySelectorStyles.css";

const CategorySelector = (props) => {
    const { menuItems, callback, shouldRenderAtThisTime } = props;

    return (
        <div className="app--categorySelectorContainer app--scrollable">
            {Object.keys(menuItems).map((category, index) => {
                if (shouldRenderAtThisTime(category)) {
                    return (
                        <a
                            key={category + index}
                            className="app-category-item"
                            onClick={() => callback({ category })}
                        >
                            {menuItems[category].categoryName}
                        </a>
                    );
                }
            })}
        </div>
    );
};
export default CategorySelector;
