import SubHeading from "../../components/SubHeading/SubHeadingComponent";
import { images } from "../../constants";
import "./HeaderStyles.css";

const Header = () => {
    return (
        <div className="app--header app--wrapper section--padding" id="home">
            <div className="app--wrapper-info">
                <SubHeading title="Experience the Culinary Adventure with Our Diverse Menu" />
                <h1 className="app--header-h1">
                    A Unique Blend of Flavors and Culture
                </h1>
                <p className="p--opensans" style={{ margin: "2rem 0" }}>
                    For more than a decade, our family-owned Japanese steakhouse
                    has been a go-to destination for foodies in search of bold
                    and exciting flavors. Our menu features a wide range of
                    dishes inspired by the cuisines of Asia and beyond,
                    carefully crafted to satisfy every craving. From savory
                    Japanese-style creations to beloved Chinese favorites, spicy
                    Thai specialties, and more, our diverse selection ensures
                    that every guest can find something to love.
                </p>
                <a href="/menu">
                    <button type="button" className="custom--button">
                        Explore Menu
                    </button>
                </a>
            </div>

            <div className="app--wrapper-img">
                <img src={images.sushiGoldBorder} alt="app wrapper" />
            </div>
        </div>
    );
};

export default Header;
