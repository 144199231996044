// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import {
    getFirestore,
    doc,
    getDoc,
    setDoc,
    collection,
    writeBatch,
    query,
    getDocs,
    orderBy,
    limit,
} from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyD2Hn3WeHvU2UoblB0EklvqLf5nIb8LA3Q",
    authDomain: "yamato-c8838.firebaseapp.com",
    projectId: "yamato-c8838",
    storageBucket: "yamato-c8838.appspot.com",
    messagingSenderId: "671259497995",
    appId: "1:671259497995:web:634d0f5afbea828aefd90e",
    measurementId: "G-RHWHYEP9HQ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const db = getFirestore();

// export const addCollectionAndDocuments = async (collectionKey, objectsToAdd) => {
//     const collectionRef = collection(db, collectionKey);
//     const batch = writeBatch(db);

//     objectsToAdd.forEach((object) => {
//         const docRef = doc(collectionRef, object.orderNumber);
//         batch.set(docRef, object);
//     });

//     await batch.commit();
//     //console.log("Batch Commit Finished");
// }

export const submitOrderToFirebase = async (
    collectionKey,
    objectsToAdd,
    orderNumber
) => {
    const collectionRef = collection(db, collectionKey);
    const batch = writeBatch(db);
    const docRef = doc(collectionRef, orderNumber);
    batch.set(docRef, { ...objectsToAdd });

    await batch.commit();
    //console.log("Batch Commit Finished");
};

export const addToMenuItems = async (collectionKey, objectsToAdd) => {
    const collectionRef = collection(db, collectionKey);
    const batch = writeBatch(db);

    objectsToAdd.forEach((object) => {
        const docRef = doc(collectionRef, object.orderNumber);
        batch.set(docRef, object);
    });

    await batch.commit();
    //console.log("Batch Commit Finished");
};

export const getOrderConfirmation = async (key, setIsValidOrder) => {
    const docRef = doc(db, "orders", key);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        //console.log("Document data:", docSnap.data());
        setIsValidOrder(true);
        return docSnap.data();
    } else {
        // doc.data() will be undefined in this case
        //console.log("No such document!");
        return null;
    }
};

export const getRecentOrders = async (numOrdersToRequest) => {
    try {
        const ordersRef = collection(db, "orders");
        const q = query(
            ordersRef,
            orderBy("orderNumber", "desc"),
            limit(numOrdersToRequest)
        );
        const querySnapshot = await getDocs(q);

        const orders = [];
        querySnapshot.forEach((doc) => {
            orders.push({ id: doc.id, ...doc.data() });
        });

        return orders;
    } catch (error) {
        console.error("Error getting documents:", error);
        return [];
    }
};
