import { useContext, useState } from "react";
import { MdRestaurantMenu } from "react-icons/md";
import SubHeading from "../../../components/SubHeading/SubHeadingComponent";
import "./CheckoutStyles.css";
import PickupTimeSelector from "./PickupTimeSelector";
import { CartContext } from "../../../contexts/CartContext";
import { submitOrderToFirebase } from "../../../utilities/firebase/firebaseUtilities";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import ReactDOM from "react-dom/server";
import Confirmation from "../Confirmation/ConfirmationComponent";
import styles from "../Cart/CartStyles.css";
import ConfirmationEmail from "../ConfirmationEmail/ConfirmationEmailComponent";
import buildEmail from "./emailBuilder";

const Checkout = (props) => {
    const { subTotal, cartItems, total } = useContext(CartContext);
    const taxRate = 0.1;
    const navigate = useNavigate();

    const [phoneNumber, setPhoneNumber] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [specialInstructions, setSpecialInstructions] = useState("");
    const [pickupTime, setPickupTime] = useState("");

    // const toEmail = process.env.REACT_APP_TO_EMAIL;
    // const fromEmail = process.env.REACT_APP_FROM_EMAIL;

    // console.log(toEmail);
    // console.log(fromEmail);
    const handlePhoneNumberInput = (event) => {
        if (!isNaN(Number(event.target.value))) {
            setPhoneNumber(event.target.value);
        }
    };

    const handleSpecialInstructionsInput = (event) => {
        setSpecialInstructions(event.target.value);
    };

    const handleEmailInput = (event) => {
        setEmail(event.target.value);
    };

    const handleFirstNameInput = (event) => {
        setFirstName(event.target.value);
    };

    const handleLastNameInput = (event) => {
        setLastName(event.target.value);
    };

    let retryCount = 3;

    const sendEmail = (data) => {
        $.ajax({
            url: "https://johngpt.io/api/email",
            type: "POST",
            contentType: "application/json",
            data: JSON.stringify(data),
            cache: false,
            success: function (data) {
                // Success..
                console.log("success", data);
            }.bind(this),
            // Fail..
            error: function (xhr, status, err) {
                console.log(xhr, status);
                console.log(err);
                retryCount--;
                console.log(
                    "email failed retryCount is at " +
                        retryCount +
                        " trying to send email again"
                );
                if (retryCount > 0) {
                    sendEmail(data);
                }
            }.bind(this),
        });
    };

    const sendTextMessage = (data) => {
        //console.log("sending text message");
        $.ajax({
            url: "https://localhost:3100/api/email",
            type: "POST",
            contentType: "application/json",
            data: JSON.stringify(data),
            cache: false,
            success: function (data) {
                // Success..
                //console.log("success", data);
            }.bind(this),
            // Fail..
            error: function (xhr, status, err) {
                console.log(xhr, status);
                console.log(err);
                retryCount--;
                console.log(
                    "textMessage Failed retryCount is at " +
                        retryCount +
                        " trying to send text again"
                );
                if (retryCount > 0) {
                    sendTextMessage(data);
                }
            }.bind(this),
        });
    };

    const sendCall = (data) => {
        //console.log("sending text message");
        $.ajax({
            url: "https://johngpt.io/api/phonecall",
            type: "POST",
            contentType: "application/json",
            data: JSON.stringify(data),
            cache: false,
            success: function (data) {
                //Success..
                console.log("success", data);
            }.bind(this),
            // Fail..
            error: function (xhr, status, err) {
                console.log(xhr, status);
                console.log(err);
                retryCount--;
                console.log(
                    "phone call failed retryCount is at " +
                        retryCount +
                        " trying to call again"
                );
                if (retryCount > 0) {
                    sendCall(data);
                }
            }.bind(this),
        });
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        const customer = {
            name: firstName + " " + lastName,
            email: email,
            phoneNumber,
            phoneNumber,
        };
        const date = new Date();
        const orderNumber = date.getTime().toString();
        const order = {
            items: [...cartItems],
            customerInfo: customer,
            subTotal: subTotal,
            total: total,
            specialInstructions: specialInstructions,
            pickupTime: pickupTime,
            dateTime: date.toDateString(),
            orderNumber: orderNumber,
        };

        submitOrderToFirebase("orders", order, orderNumber);

        const emailBody = buildEmail(order);

        //console.log(emailBody);

        let emailData = {
            from_email: "Johnnylin0588@gmail.com",
            to_email: "JacksonvilleYamato1@gmail.com",
            subject: "Online Order",
            msg: emailBody,
            name: "Online Order",
        };
        sendEmail(emailData);

        const orderInformation =
            "You have an incomming online order from " +
            customer.name +
            " in the amount of " +
            total +
            " dollars please check the iPad";

        let twiml = `<Response>
        <Say voice="alice">`;

        twiml += orderInformation;

        twiml += `</Say>
        <Play>http://demo.twilio.com/docs/classic.mp3</Play>
        </Response>`;

        const callData = {
            body: twiml,
            from_number: +18449961123,
            to_number: +12563656231,
            // to_number: +13474455136,
        };

        sendCall(callData);

        let textMessageContent =
            "/confirmation?orderNumber=" +
            orderNumber +
            "&c=NewOnlineOrderFrom" +
            firstName +
            total;

        const textData = {
            body: textMessageContent,
            from_number: +18449961123,
            to_number: +12563656231,
        };

        //sendTextMessage(textData);

        navigate("/confirmation?orderNumber=" + orderNumber);
    };

    return (
        <div className="scale-in-center checkout-window-container app--scrollable">
            <div className="modifiers-window app--newsletter app--scrollable">
                <MdRestaurantMenu
                    fontSize={27}
                    className="modifier--close"
                    onClick={props.closeCheckout}
                />
                <div className="modifierTitle-container">
                    <SubHeading title={"Checkout"} />
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="checkout-fields-container">
                        <div className="checkout-row-container">
                            <div className="checkout-input-container">
                                <div>
                                    <label>
                                        <p className="p--opensans">
                                            First Name
                                        </p>
                                    </label>
                                </div>
                                <div className="app--checkout-input">
                                    <input
                                        name="firstName"
                                        value={firstName}
                                        required
                                        onChange={handleFirstNameInput}
                                    ></input>
                                </div>
                            </div>
                            <div className="checkout-input-container">
                                <div>
                                    <label>
                                        <p className="p--opensans">Last Name</p>
                                    </label>
                                </div>
                                <div className="app--checkout-input">
                                    <input
                                        name="lastName"
                                        value={lastName}
                                        required
                                        onChange={handleLastNameInput}
                                    ></input>
                                </div>
                            </div>
                        </div>
                        <div className="checkout-row-container">
                            <div className="checkout-input-container">
                                <div>
                                    <label>
                                        <p className="p--opensans">Email</p>
                                    </label>
                                </div>
                                <div className="app--checkout-input">
                                    <input
                                        type={"email"}
                                        required
                                        name="email"
                                        value={email}
                                        onChange={handleEmailInput}
                                    ></input>
                                </div>
                            </div>
                            <div className="checkout-input-container">
                                <div>
                                    <label>
                                        <p className="p--opensans">
                                            Phone Number
                                        </p>
                                    </label>
                                </div>
                                <div className="app--checkout-input">
                                    <input
                                        type={"tel"}
                                        value={phoneNumber}
                                        onChange={handlePhoneNumberInput}
                                        name="phoneNumber"
                                        required
                                    ></input>
                                </div>
                            </div>
                        </div>
                        <div className="order-modifier-entry-container">
                            <p className="p--opensans">
                                special order instructions?
                            </p>
                            <textarea
                                className="order-modifier-entry"
                                placeholder="optional..."
                                value={specialInstructions}
                                onChange={handleSpecialInstructionsInput}
                            />
                        </div>
                        <div className="checkout-footer-container">
                            <div className="checkout-footerItem-container">
                                <label>
                                    <p className="p--opensans">Pickup Time</p>
                                </label>

                                <PickupTimeSelector
                                    setPickupTime={setPickupTime}
                                />
                            </div>
                            <div className="checkout-footerItem-container">
                                <label>
                                    <p className="p--opensans">
                                        Payment Method
                                    </p>
                                </label>
                                <select id="cars" name="cars">
                                    <option value="cash">Pay At Store</option>
                                </select>
                            </div>

                            <div className="checkout-footerItem-container">
                                <label className="order-total-label">
                                    <p className="p--opensans">Order Total</p>
                                </label>
                                <div className="order-total-container">
                                    <h2 className="p--opensans">${total}</h2>
                                </div>
                            </div>
                            <div className="checkout-footerItem-container">
                                <button
                                    className="custom--button order-submit-button"
                                    href="/online-order/confirmation"
                                >
                                    Submit Order
                                </button>
                            </div>
                        </div>
                        {/* ToDo make this conditional if order contains modifiers */}
                        <div className="checkout-footer-container">
                            Please note that you are currently placing an order
                            at the Jacksonville AL location
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Checkout;
