import "./NavBarStyles.css";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdRestaurantMenu } from "react-icons/md";
import images from "../../constants/images.js";
import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const NavBar = () => {
    const [toggleMenu, setToggleMenu] = useState(false);
    const navigate = useNavigate();

    return (
        <React.Fragment>
            <div className="app--navbar">
                <div
                    className="app--navbar-logo"
                    onClick={() => {
                        navigate("/");
                    }}
                >
                    <img src={images.siteLogo} alt="title logo" />
                </div>
                <ul className="app--navbar-links">
                    <li className="p--opensans">
                        <HashLink to="/#home">Home</HashLink>
                    </li>
                    <li className="p--opensans">
                        <HashLink to="/#about">About</HashLink>
                    </li>
                    <li className="p--opensans">
                        <HashLink to="/#menu">Specials</HashLink>
                    </li>
                    <li className="p--opensans">
                        <HashLink to="/#contact">Contact</HashLink>
                    </li>
                </ul>
                <div className="app--navbar-order">
                    <a href="/online-order" className="p--opensans">
                        Order Online
                    </a>
                    <div></div>
                    <a href="/menu" className="p--opensans">
                        Full Menu
                    </a>
                </div>
                <div className="app--navbar-smallscreen">
                    <GiHamburgerMenu
                        color="#fff"
                        fontSize={27}
                        onClick={() => {
                            setToggleMenu(true);
                        }}
                    />
                    {toggleMenu && (
                        <div className="app--navbar-smallscreen-overlay flex--center slide-bottom">
                            <MdRestaurantMenu
                                fontSize={27}
                                className="overlay--close"
                                onClick={() => {
                                    setToggleMenu(false);
                                }}
                            />
                            <ul className="app--navbar-smallscreen-links">
                                <li
                                    className="p--opensans"
                                    onClick={() => {
                                        setToggleMenu(false);
                                    }}
                                >
                                    <HashLink to="/#home">Home</HashLink>
                                </li>
                                <li
                                    className="p--opensans"
                                    onClick={() => {
                                        setToggleMenu(false);
                                    }}
                                >
                                    <HashLink to="/#about">About</HashLink>
                                </li>
                                <li
                                    className="p--opensans"
                                    onClick={() => {
                                        setToggleMenu(false);
                                    }}
                                >
                                    <HashLink to="/#menu">Specials</HashLink>
                                </li>
                                <li
                                    className="p--opensans"
                                    onClick={() => {
                                        setToggleMenu(false);
                                    }}
                                >
                                    <HashLink to="/#contact">Contact</HashLink>
                                </li>
                                <li
                                    className="p--opensans app--navbar-smallerscreen"
                                    onClick={() => {
                                        setToggleMenu(false);
                                    }}
                                >
                                    <a href="/online-order">Online Order</a>
                                </li>
                                <li
                                    className="p--opensans app--navbar-smallerscreen"
                                    onClick={() => {
                                        setToggleMenu(false);
                                    }}
                                >
                                    <a href="/menu">Full Menu</a>
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
            </div>
            <Outlet />
        </React.Fragment>
    );
};
export default NavBar;
