import "./ModifiersStyles.css";
import { MdRestaurantMenu } from "react-icons/md";
import SubHeading from "../../../components/SubHeading/SubHeadingComponent";
import { useState, useContext, useEffect } from "react";
import FoodItemQuantitySelector from "../FoodItemQuantitySelector/FoodItemQuantitySelectorComponent";
import Modification from "./Modification/ModificationComponent";
import { MenuItemsContext } from "../../../contexts/MenuItemsContext";

const Modifiers = (props) => {
    const [modifierMessage, setModifierMessage] = useState("");

    const [itemModifiers, setItemModifiers] = useState([]);

    const {
        incrementQuantity,
        decrementQuantity,
        quantity,
        itemType,
        submitToCart,
        item,
        specificModifiers,
    } = props;

    const { modifiers } = useContext(MenuItemsContext);

    const getIndexForItemType = () => {
        let indexOfItemType = undefined;
        modifiers.map((entry, index) => {
            if (entry.categoryName === itemType) {
                indexOfItemType = index;
            }
        });
        return indexOfItemType;
    };

    let modificationArray = undefined;

    if (modifiers[getIndexForItemType()] != undefined) {
        modificationArray = modifiers[getIndexForItemType()].modifiers;
    }

    if (specificModifiers !== undefined) {
        modificationArray = [...specificModifiers];
    }

    const addModificationCallback = (isChecked, modificationEntry) => {
        if (isChecked) {
            setItemModifiers([...itemModifiers, modificationEntry]);
        } else {
            removeModification(modificationEntry.name);
        }
    };

    const handleSubmitToCart = () => {
        let item = [...itemModifiers, { name: modifierMessage, price: 0 }];
        submitToCart(item);
    };

    const removeModification = (name) => {
        let indexToRemove = undefined;
        itemModifiers.map((entry, index) => {
            if (entry.name == name) {
                indexToRemove = index;
            }
        });

        if (indexToRemove != undefined) {
            itemModifiers.splice(indexToRemove, 1);
        }
    };

    return (
        <div className="scale-in-center modifiers-window-container app--scrollable">
            <div className="modifiers-window app--newsletter app--scrollable">
                <MdRestaurantMenu
                    fontSize={27}
                    className="modifier--close"
                    onClick={props.closeModifierWindow}
                />
                <div className="modifierTitle-container">
                    <SubHeading title={props.item.name} />
                </div>
                <div className="app--scrollable">
                    {modificationArray != undefined &&
                    modificationArray.length != 0 ? (
                        itemType !== "Poke Bowl/Sushi Burrito" &&
                        itemType !== "Boba Teas" ? (
                            <p className="p--opensans">Common Modifications:</p>
                        ) : (
                            <></>
                        )
                    ) : (
                        <></>
                    )}

                    {modificationArray != undefined ? (
                        modificationArray.map((entry, index) => {
                            if (entry.label !== undefined) {
                                return (
                                    <div>
                                        <h2>{entry.label}</h2>
                                    </div>
                                );
                            }
                            return (
                                <Modification
                                    key={index + ": " + entry.name}
                                    entry={entry}
                                    addModificationCallback={
                                        addModificationCallback
                                    }
                                />
                            );
                        })
                    ) : (
                        <></>
                    )}
                </div>
                {modificationArray != undefined &&
                modificationArray.length != 0 ? (
                    <div className="horizontal-divider"></div>
                ) : (
                    <></>
                )}

                <div className="modifierMessage-entry-container">
                    <p className="p--opensans">special instructions?</p>
                    <textarea
                        className="modifier-message-entry"
                        placeholder="optional..."
                        onChange={(event) => {
                            setModifierMessage(event.target.value);
                        }}
                    />
                </div>
                <FoodItemQuantitySelector
                    incrementQuantity={incrementQuantity}
                    decrementQuantity={decrementQuantity}
                    quantity={quantity}
                />
                <button onClick={handleSubmitToCart} className="custom--button">
                    Add To Cart
                </button>
            </div>
        </div>
    );
};

export default Modifiers;
